import { RightOutlined } from "@ant-design/icons"
import { Checkbox, TextField } from "@project/shared"
import { TextFieldProps } from "@project/shared/src/components/atom/TextField"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .label-wrapper {
    min-width: 240px;
    label {
      margin-left: 10px;
    }
    .icon {
      color: #d2d1d1;
    }
  }
  .use-service-label {
    min-width: 175px;
  }
  .text-field-wrapper {
    display: flex;
    input {
      background: #ffffff;
      border: 1px solid #d2d1d1;
      border-radius: 5px;
      width: 300px;
      height: 40px;

      @media (max-width: 456px) {
        width: 100%;
      }
    }
  }
`

const InputWithArrowedLabel: React.FC<TextFieldProps> = (props) => {
  const { t } = useTranslation()

  const serviceTypes = [
    {
      label: t("Adult"),
      value: "A",
    },
    {
      label: t("Child"),
      value: "C",
    },
  ]

  return (
    <Wrapper>
      <div className={`label-wrapper ${props?.className}`}>
        <RightOutlined className={"icon"} />
        <label>{props.label}</label>
      </div>
      <div className={"text-field-wrapper"}>
        {props.type === "checkbox" ? (
          serviceTypes.map((val) => {
            return (
              <Checkbox
                label={val.label}
                key={val.value}
                value={val.value}
                checked={props.value.includes(val.value)}
                onChange={(evt) => {
                  props.onChange(evt.target.value, evt.target.checked)
                }}
              />
            )
          })
        ) : (
          <TextField {...props} label={""} />
        )}
      </div>
    </Wrapper>
  )
}

export { InputWithArrowedLabel }
