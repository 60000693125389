import { Button as SharedButton } from "@project/shared"
import { ButtonProps } from "@project/shared/src/components/atom/Button"
import React from "react"
import styled, { css } from "styled-components"

interface iButton extends ButtonProps {
  typeOf: "primary" | "secondary"
}

const StyledButton = styled(SharedButton)<iButton>`
  ${({ typeOf }) =>
    typeOf === "primary"
      ? css`
          color: #f3f3f3;
          background-color: #0782c8;
          border-color: #0782c8;
          &:hover {
            color: #f3f3f3;
            background-color: #0782c8;
            border-color: #0782c8;
            opacity: 0.8;
          }
          &:focus {
            color: #f3f3f3;
            background-color: #0782c8;
            border-color: #0782c8;
            opacity: 0.8;
          }
        `
      : css`
          color: #191919;
          background-color: #f3f3f3;
          border: 1px solid #d2d1d1;
          &:hover {
            color: #191919;
            background-color: #f3f3f3;
            border: 1px solid #191919;
            opacity: 0.8;
          }
          &:focus {
            color: #191919;
            background-color: #f3f3f3;
            border: 1px solid #191919;
            opacity: 0.8;
          }
        `}
  padding-left:30px;
  padding-right: 30px;
  height: 40px;
`

const Button: React.FC<iButton> = (props) => {
  const { typeOf } = props
  return <StyledButton typeOf={typeOf} {...props} />
}

export { Button }
