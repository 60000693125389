import React from "react"
import {
  Table as SharedTable,
  Pagination as SharedPagination,
} from "@project/shared"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

interface IOperationTableProps {
  dataSource: any
  columns: any
  total: number
  pageSize: number
  currentPage: number
  handlePaginationChange: (a: number) => void
  loading?: boolean
  scroll?: any
}

const Wrapper = styled.div`
  .main-pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
  }
  .bottom-pagination-wrapper {
    margin-top: 10px;
    padding: 0px;
  }

  .custom-table-stylizer {
    table {
      border-collapse: collapse;
      thead {
        tr {
          background: #f3f3f3;
          th:first-child {
            border-top-left-radius: 12px;
          }
          th:last-child {
            border-top-right-radius: 12px;
          }
        }
      }
      tbody {
        tr td {
          border: 1px solid #d2d1d1 !important;
        }
      }
    }
  }
`

const OperationTable: React.FC<IOperationTableProps> = ({
  dataSource,
  columns,
  total,
  pageSize,
  currentPage,
  scroll,
  handlePaginationChange,
  loading = false,
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className={"main-pagination-wrapper"}>
        <div>
          <p>{t("Total {{total}} datas", { total })}</p>
        </div>
        <div>
          <SharedPagination
            total={total}
            current={currentPage}
            pageSize={pageSize}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
      <div className={"custom-table-stylizer"}>
        <SharedTable
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          scroll={scroll}
        />
      </div>
      <div className={"main-pagination-wrapper bottom-pagination-wrapper"}>
        <div>
          <p>{t("Total {{total}} datas", { total })}</p>
        </div>
        <div>
          <SharedPagination
            total={total}
            current={currentPage}
            pageSize={pageSize}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export { OperationTable }
