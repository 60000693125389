import styled from "styled-components"

const Footer = styled.footer`
  display: flex;
  background: white;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0px;
  height: 80px;
  .copyright-text {
    font-size: 12px;
    margin: auto;
    margin-right: 60px;
  }
`
const UnAuthorizedFooter = () => {
  return (
    <Footer>
      <div>
        <p className={"copyright-text"}>{"Copyright © Onelife.Inc"}</p>
      </div>
    </Footer>
  )
}

export { UnAuthorizedFooter }
