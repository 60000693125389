import React from "react"
import { Button } from "./index"
import SearchIcon from "../../../public/assets/icons/SearchIcon"
import styled from "styled-components"

interface SearchButtonProps {
  label?: string
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const StyledButton = styled(Button)`
  .search-icon {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
`

const SearchButton: React.FC<SearchButtonProps> = ({
  label = "検索",
  onClick,
}) => (
  <StyledButton typeOf={"primary"} onClick={onClick}>
    <span className={"search-icon"}>
      <SearchIcon />
      {label}
    </span>
  </StyledButton>
)

export { SearchButton }
