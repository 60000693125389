import { useFormik } from "formik"
import * as yup from "yup"
import React from "react"
import { message } from "antd"
import { useTranslation } from "react-i18next"
import Head from "next/head"
import { TextField, Button, auth, theme } from "@project/shared"
import styled from "styled-components"
import * as Sentry from "@sentry/node"
import {
  CloseCircleFilled,
  EyeFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons"
import { signInWithEmailAndPassword } from "firebase/auth"
import RestrictedRoute from "../../withRestrictedRoute"
import {
  UnAuthorizedHeader as Header,
  UnAuthorizedFooter as Footer,
} from "../../components"

interface LoginType {
  email: string
  password: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const Container = styled.div`
  background: ${theme.bgColor};
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 0px;
  flex: 1;
  .top-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      margin: 17px 0;
      font-size: 18px;
      font-weight: 500;
    }
    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 133px;
      width: 133px;
      background: white;
      border-radius: 50%;
    }
  }
`

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.darkGrey};
  background: ${theme.base};
  padding: 1rem 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  .login-button-wrapper {
    margin-bottom: 61px;
  }
`

const TextFieldWrapper = styled.div`
  margin-top: 35px;
  .forget-password-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 27px;
    p {
      margin: auto 0px;
      font-size: 14px;
    }
    a {
      text-decoration: underline;
      margin-left: 5px;
      font-size: 14px;
    }
  }
  .login-button-wrapper {
    text-align: center;
  }
  .password-info-text {
    font-size: 8px;
    color: ${theme.secondaryDark};
  }
`

const InputFieldWrapper = styled.div`
  margin-bottom: 25px;

  .password-field {
    position: relative;
  }

  .eye-icon {
    position: absolute;
    right: 10px;
    top: 40px;
    cursor: pointer;
  }
`

const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)

  const [passwordVisible, setPasswordVisible] = React.useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleLoginFormSubmit = () => {
    handleLogin()
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email(t("Enter Email")).required(t("Enter Email")),
    password: yup.string().required(t("Enter Password")),
  })

  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  })

  const handleLogin = async () => {
    setLoading(true)
    try {
      const data = await signInWithEmailAndPassword(
        auth,
        formik.values.email,
        formik.values.password
      )

      if (!data || !data.user || !auth.currentUser) {
        return
      }
    } catch (error) {
      const errorCode = error.code
      if (errorCode === "auth/user-not-found") {
        message.error({
          content: t("Wrong ID or password."),
          key: "1",
          icon: <CloseCircleFilled onClick={() => message.destroy("1")} />,
        })
      } else if (errorCode === "auth/wrong-password") {
        message.error({
          content: t("Wrong ID or password."),
          key: "2",
          icon: <CloseCircleFilled onClick={() => message.destroy("2")} />,
        })
      } else if (errorCode === "auth/user-disabled") {
        message.error({
          content: t(
            "We could not login you at this moment. Please contact your administration for inquiry"
          ),
          key: "3",
          icon: <CloseCircleFilled onClick={() => message.destroy("3")} />,
        })
      } else {
        message.error({
          key: "4",
          icon: <CloseCircleFilled onClick={() => message.destroy("4")} />,
          content: t("Something went wrong. Please contact administrator."),
        })
      }
      Sentry.captureException(error)
    }
    setLoading(false)
  }

  return (
    <>
      <Head>
        <title>{t("easyy Operation management login")}</title>
      </Head>
      <Wrapper>
        <Header />
        <Container style={{ flex: 1, paddingBottom: "50px" }}>
          <div className={"top-content-wrapper"}>
            <div className={"logo-wrapper"}>
              <img src={"./assets/icons/logo.svg"} />
            </div>
            <h3>{t("easyy Operation management login")}</h3>
          </div>
          <LoginWrapper>
            <TextFieldWrapper>
              <form onSubmit={formik.handleSubmit}>
                <InputFieldWrapper>
                  <TextField
                    name={"email"}
                    error={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("Email")}
                    placeholder={t("Enter Email")}
                    className={"text-field"}
                    height={"40px"}
                    width={"340px"}
                    disableboxshadow
                    hasListStyle
                    listStyleLabelColor={"#FDD95F"}
                  />
                </InputFieldWrapper>

                <InputFieldWrapper>
                  <div className={"password-field"}>
                    <TextField
                      name={"password"}
                      type={passwordVisible ? "text" : "password"}
                      error={formik.touched.password && formik.errors.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Password")}
                      placeholder={t("Enter Password")}
                      className={"text-field"}
                      height={"40px"}
                      width={"340px"}
                      disableboxshadow
                      hasListStyle
                      listStyleLabelColor={"#FDD95F"}
                    />
                    <span
                      className={"eye-icon"}
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <EyeFilled /> : <EyeInvisibleFilled />}
                    </span>
                  </div>
                </InputFieldWrapper>

                <div className={"login-button-wrapper"}>
                  <Button
                    htmlType={"submit"}
                    loading={loading}
                    background={"#FDD95F"}
                    bordercolor={"#FDD95F"}
                    color={"black"}
                    minheight={43}
                  >
                    {t("login")}
                  </Button>
                </div>
              </form>
            </TextFieldWrapper>
          </LoginWrapper>
        </Container>
        <Footer />
      </Wrapper>
    </>
  )
}

export default RestrictedRoute(LoginPage)
